<section id="about" class="about">
  <div class="about__container">
    <h2 class="about__title">About me</h2>
    <div class="about__info">
      <p class="about__text">
        I'm currently a frontend developer.
        I am a software developer who likes to know different technologies focused mainly on web development.
        I have knowledge of both frontend and backend languages, having a greater affinity for frontend development.
      </p>
      <p class="about__text">
        Now I am applying all my knowledge in my position "Frontend Developer Junior" in a company that allows me to grow personally and professionally.
      </p>
      <p class="about__text">
        I have a great motivation to continue learning about this world and to be able to perform in this profession.
      </p>
    </div>
  </div>
</section>
