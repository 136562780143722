<section id="education" class="education">
  <div class="education__container">
    <h2 class="education__title">Education</h2>
    <ul class="item-list">
      <li class="item" *ngFor="let item of items">
        <h3 class="item__name">{{ item.name }}</h3>
        <p class="item__place">{{ item.place }}</p>
        <p class="item__year">{{ item.year }}</p>
      </li>
    </ul>
  </div>
</section>
